@font-face {
  font-family: "Cerebri Sans";
  src: local("Cerebri Sans"),
    url("./CerebriSans/CerebriSans.ttf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "Cerebri Sans";
  src: local("Cerebri Sans"),
    url("./CerebriSans/CerebriSans500.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: "Cerebri Sans";
  src: local("Cerebri Sans"),
    url("./CerebriSans/CerebriSans600.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Cerebri Sans";
  src: local("Cerebri Sans"),
    url("./CerebriSans/CerebriSans800.ttf") format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: "Cerebri Sans";
  src: local("Cerebri Sans"),
    url("./CerebriSans/CerebriSans900.ttf") format("truetype");
  font-weight: 900;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
