.PhoneInput {
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.PhoneInputInput {
  font-size: 16px;
  padding: 16px;
  border: 0.5px solid #bdbdbd !important;
  border-radius: 5px;
  outline: none;
}

.PhoneInputCountryIconImg {
  height: 40px;
  width: 40px;
}

.PhoneInputCountryIcon {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInputCountryIcon--border {
  background-color: white;
  border: 0.5px solid #bdbdbd !important;
  box-shadow: none;
  border-radius: 5px;
}
